import React from 'react';
import {Checkbox} from '../general/Checkbox';

interface Props {
  isFree: boolean,
  setIsFree: (isFree: boolean) => void,
  isNew: boolean,
  setIsNew: (isNew: boolean) => void,
  setIsPopular: (isPremium: boolean) => void,
  setIsUniversal: (isUniversal: boolean) => void,
  setIsHotTrends: (isHotTrends: boolean) => void,
  setIsBestForRecaps: (isBestForRecaps: boolean) => void,
  isUniversal: boolean,
  isHotTrends: boolean,
  isPopular: boolean,
  isBestForRecaps: boolean
}

export const CheckboxTemplateDetails = ({
  isFree, setIsFree, isNew, setIsNew, setIsPopular, isPopular, setIsUniversal, setIsHotTrends, setIsBestForRecaps, isHotTrends, isUniversal, isBestForRecaps
}: Props) => {
  const checkBoxStyle = {marginRight: '12px'};
  return <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', gap: '3px'}}>
    <div className={'reels-second-text'}>
      Add to Section
    </div>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      <Checkbox
        key={'free-checkbox'}
        switch={setIsFree}
        isActive={isFree}
        style={checkBoxStyle}
        text={'Free'}
      />

      <Checkbox
        key={'best-for-recaps-checkbox'}
        switch={setIsBestForRecaps}
        isActive={isBestForRecaps}
        style={checkBoxStyle}
        text={'Best For Recaps'}
      />

      <Checkbox
        key={'new-checkbox'}
        isActive={isNew}
        style={checkBoxStyle}
        switch={setIsNew}
        text={'New'}
      />

      <Checkbox
        key={'popular-checkbox'}
        style={checkBoxStyle}
        switch={setIsPopular}
        isActive={isPopular}
        text={'Popular'}
      />

      <Checkbox
        key={'universal-checkbox'}
        style={checkBoxStyle}
        switch={setIsUniversal}
        isActive={isUniversal}
        text={'Universal'}
      />

      <Checkbox
        key={'hot-trends-checkbox'}
        style={checkBoxStyle}
        switch={setIsHotTrends}
        isActive={isHotTrends}
        text={'Hot trends'}
      />
    </div>
  </div>
};
