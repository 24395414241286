import React, {useState, useEffect} from 'react';
import {EditImg} from '../general/EditImg';
import {Input} from '../general/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFileNameExt, uploadFileToS3Post} from '../../utils/FilesUtils';
import uuid from 'uuid';
import {DetailsFooter} from '../general/DetailsFooter';
import {CheckboxTemplateDetails} from './CheckboxTemplatesDetails';
import {addTemplate, compressImage, updateTemplate} from "../../actions/templates";
import {AudioUrls, Template} from "../../models/Template";
import {DetailsModeType, PlatformType, reservedCategoriesIds} from '../../models/Common';
import {Category} from "../../models/Category";
import {TemplatesByCategoryId, TemplatesEventCount} from "../../reducers/templates";
import {Tag} from "../../models/Tag";
import {Version} from "../../models/Version";
import {EditVideo} from "../general/EditVideo";
import {TagsInput} from "../general/TagsInput";
import {TemplateClipsView} from "./TemplateClipsView";
import {configs} from "../../resources/configs";
import {ReactComponent as ViewIcon} from '../../resources/imgs/view-icon.svg';
import {ReactComponent as ExportIcon} from '../../resources/imgs/export-icon.svg';
import {ReactComponent as ArrowButton} from '../../resources/imgs/arrow-button-icon.svg';
import momentZ from 'moment-timezone';
import {Badge} from "../general/Badge";
import {AudioFileInput} from "../general/AudioFileInput";
import {Checkbox} from '../general/Checkbox';
import {compressVideo} from '../../actions/general';
import MoveTo from '../general/MoveTo';
import DropdownWrapper from '../general/DropdownWrapper';
import {Collection} from '../../models/Collection';
import {TemplateToCollection} from '../../models/TemplateToCollection';

interface StateProps {
  categories: Category[],
  collections: Collection[],
  templatesByCategoriesIds: TemplatesByCategoryId,
  templatesCount: number,
  tags: Tag[],
  nextVersion: Version,
  currentVersion: Version,
  templatesViewCount: TemplatesEventCount,
  templatesExportCount: TemplatesEventCount,
  isCompressingVideo: boolean,
  isCompressingImage: boolean
}

interface DispatchProps {
  addTemplate: (template: Template, isUpdateRelations: boolean) => void,
  updateTemplate: (template: Template, isUpdateRelations: boolean) => void,
  compressVideo: (videoUrl: string) => void,
  compressImage: (imageUrl: string, resultFileName: string) => void
}

interface OwnProps {
  template: Template,
  mode: DetailsModeType,
  cancel: () => void,
  deleteTemplate?: (template: Template) => void,
  meta?: any
}

type Props = StateProps & DispatchProps & OwnProps;

const TemplateDetails = ({template, mode, categories, cancel, deleteTemplate, templatesByCategoriesIds, tags, isCompressingVideo,
  addTemplate, updateTemplate, templatesCount, nextVersion, currentVersion, meta, templatesViewCount, templatesExportCount, compressVideo,
  compressImage, isCompressingImage, collections
}: Props) => {
  const [id, setId] = useState(mode === DetailsModeType.ADD ? uuid.v4() : template.id);
  const [isUpdateRelations, setIsUpdateRelations] = useState(false);
  const [isVisible, setIsVisible] = useState(template.isActive);
  const [isNew, setIsNew] = useState(mode === DetailsModeType.EDIT ? template.isNew : meta.categoryId === 'new');
  const [isPopular, setIsPopular] = useState(mode === DetailsModeType.EDIT ? template.isPopular : meta.categoryId === 'most_popular');
  const [isFree, setIsFree] = useState(mode === DetailsModeType.EDIT ? template.isFree : meta.categoryId === 'free');
  const [isUniversal, setIsUniversal] = useState(mode === DetailsModeType.EDIT ? template.isUniversal : meta.categoryId === 'universal_templates');
  const [isHotTrends, setIsHotTrends] = useState(mode === DetailsModeType.EDIT ? template.isHotTrends : meta.categoryId === 'hot_trends');
  const [isBestForRecaps, setIsBestForRecaps] = useState(mode === DetailsModeType.EDIT ? template.isBestForRecaps : meta.categoryId === 'best_for_recaps');
  const [artistName, setArtistName] = useState(template.artistName);
  const [audioUrl, setAudioUrl] = useState(template.audioUrl);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [isValidClips, setIsValidClips] = useState(true);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(template.previewImageUrl);
  const [audioUrls, setAudioUrls] = useState<AudioUrls>(mode === DetailsModeType.EDIT ? (template.audioUrls ? template.audioUrls : {}) : {});
  const [trackName, setTrackName] = useState(template.trackName);
  const [previewVideoUrl, setPreviewVideoUrl] = useState<string | null>(template.previewVideoUrl);
  const [clips, setClips] = useState(template.clips);
  const [templateToCollections, setTemplateToCollections] = useState(mode === DetailsModeType.EDIT ? template.templateToCollections : []);
  const [templateToCategories, setTemplateToCategories] = useState(mode === DetailsModeType.EDIT ? template.templateToCategories : []);
  const [tagIds, setTagIds] = useState(template.tagIds);
  const [joinCollection, setJoinCollection] = useState<Collection | null | undefined>(mode === DetailsModeType.EDIT ?
    (template.templateToCollections.length !== 0 ? collections.find(collection => collection.id ===  template.templateToCollections[0].collectionId) : null)
    : null
  );
  console.log(meta)
  useEffect(() => {
    if(meta.categoryId) {
      handleClickCategory(meta.categoryId, true)
    }
  }, [meta.categoryId]);

  useEffect(() => {
    if(meta.collectionId) {
      setJoinCollection(collections.find(collection => collection.id === meta.collectionId));
    }
  }, [meta.collectionId]);

  const isEmptyAudioUrls = () => {
    let isEmpty = true;
    Object.keys(audioUrls).forEach((platform) => {
      if(isEmpty && audioUrls[platform]) {
        isEmpty = audioUrls[platform] === '';
      }
    })

    return isEmpty || !audioUrl || audioUrl === '';
  }

  const handleFileDrop = (file: any, fileName: string, setIsLoading: (isLoading: boolean) => void, setUrl: (path: string) => void) => {
    if (file) {
      setIsLoading(true);
      uploadFileToS3Post(file, fileName, 'RES_REELS', `templates/${id}`, (path: string) => {
        setUrl(configs.resUrl + path);
        setIsLoading(false);
      });
    }
  };

  const handleClickCategory = (categoryId: string, isActive: boolean) => {
    switch (categoryId) {
      case 'free': {
        setIsFree(isActive);
        break;
      }
      case 'new': {
        setIsNew(isActive);
        break;
      }
      case 'most_popular': {
        setIsPopular(isActive);
        break;
      }
      case 'universal_templates': {
        setIsUniversal(isActive);
        break;
      }
      case 'hot_trends': {
        setIsHotTrends(isActive);
        break;
      }
      case 'best_for_recaps': {
        setIsBestForRecaps(isActive);
        break;
      }
      default: break;
    }

    const newTemplateToCategories = [...templateToCategories];

    const templateToCategory = newTemplateToCategories.find((newTemplateToCategory) => {
      return newTemplateToCategory.categoryId === categoryId;
    });
    if (isActive && !templateToCategory) {
      let order = 0;
      if(templatesByCategoriesIds[categoryId]) {
        templatesByCategoriesIds[categoryId].forEach(template => {
          const templateToCategory = template.templateToCategories.find((templateToCategory) => templateToCategory.categoryId === categoryId);
          if(templateToCategory && templateToCategory.order <= order) {
            order = templateToCategory.order - 1;
          }
        })
      }

      newTemplateToCategories.push({
        categoryId,
        templateId: id,
        templateToCategoryId: uuid.v4(),
        order: order
      });
    }

    if (!isActive && templateToCategory) {
      const index = newTemplateToCategories.indexOf(templateToCategory);
      newTemplateToCategories.splice(index, 1);
    }
    setIsUpdateRelations(true);
    setTemplateToCategories(newTemplateToCategories);
  };

  const handleSaveTemplate = () => {
    const templateForSave = {...template};
    templateForSave.id = id;
    templateForSave.isActive = isVisible;
    templateForSave.isNew = isNew;
    templateForSave.isPopular = isPopular;
    templateForSave.isUniversal = isUniversal;
    templateForSave.isHotTrends = isHotTrends;
    templateForSave.isBestForRecaps = isBestForRecaps;
    templateForSave.artistName = artistName;
    templateForSave.trackName = trackName;
    templateForSave.audioUrl = audioUrl;
    templateForSave.version = nextVersion.id;
    templateForSave.templateToCategories = templateToCategories;
    templateForSave.tagIds = tagIds;
    templateForSave.isFree = isFree;
    templateForSave.previewImageUrl = previewImageUrl!;
    templateForSave.audioUrls = audioUrls;
    templateForSave.previewVideoUrl = previewVideoUrl!;
    templateForSave.clips = clips!;
    templateForSave.updatedAt = new Date();
    templateForSave.templateToCollections = templateToCollections;
    if (joinCollection) {
      if(!templateToCollections.find(templateToCollections => templateToCollections.collectionId === joinCollection.id)) {
        let minOrder = 0;
        joinCollection.templatesToCollection.forEach(templateToCollections => {
          if(templateToCollections.order <= minOrder) {
            minOrder = templateToCollections.order - 1;
          }
        })
        const templateToCollection: TemplateToCollection = {
          templateToCollectionId: uuid.v4(),
          order: minOrder,
          templateId: templateForSave.id,
          collectionId: joinCollection.id
        }
        templateForSave.templateToCollections[0] = templateToCollection;
      }
    } else {
      templateForSave.templateToCollections = [];
    }

    mode === DetailsModeType.EDIT ? updateTemplate(templateForSave, isUpdateRelations) : addTemplate(templateForSave, isUpdateRelations);
    cancel();
  };

  const renderCategories = () => {
    return <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'reels-second-text'}>
        Add to Category
      </div>
      <div className={'reels-badge-container'}>
        {categories
            .filter(category => reservedCategoriesIds.indexOf(category.id) === -1)
            .sort((a, b) => {return a.order - b.order})
            .map((category) => {
              return <Badge
                  key={category.id}
                  onChange={(isActive)=>{
                    handleClickCategory(category.id, isActive);
                  }}
                  value={category.name}
                  isActive={!!templateToCategories.find((templateToCategory) => {
                    return templateToCategory.categoryId === category.id;
                  })}
              />;
            })}
      </div>
    </div>;
  };

  const renderHeaderInfo = () => {
    if(mode === DetailsModeType.ADD) {
      return null;
    }
    const viewCount = templatesViewCount[template.id] ? templatesViewCount[template.id] : 0;
    const exportCount = templatesExportCount[template.id] ? templatesExportCount[template.id] : 0;
    let published = '';
    if(currentVersion && momentZ(currentVersion.publishDate).isAfter(momentZ(template.createdAt))) {
      let daysAgo = 0;
      if(currentVersion.id === template.version) {
        daysAgo = momentZ().diff(momentZ(currentVersion.publishDate), 'days');
      } else {
        daysAgo = momentZ().diff(momentZ(template.createdAt), 'days');
      }
      published = `Published ${daysAgo}d ago`;
    }

    return <div style={{display: 'flex', alignItems: 'center', paddingTop: '4px'}}>
      <div className={'template-list-stats-container'}>
        <div className={'template-list-stats'}>
          <ViewIcon style={{paddingRight: '3px'}}/>
          {viewCount}
        </div>
        <div className={'template-list-stats'} style={{paddingLeft: '12px'}}>
          <ExportIcon style={{paddingRight: '3px'}}/>
          {exportCount}
        </div>
      </div>
      <div style={{paddingLeft: '12px'}} className={'published-value'}>
        {published}
      </div>
    </div>
  }

  const renderHeader = () => {
    return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <div className={'details-header'} style={{flexDirection: 'column'}}>
        <div className={'reels-head-text'}>
          {mode === DetailsModeType.EDIT ? 'Edit Template' : 'Add Template'}
        </div>
        <div className={'reels-second-text'}>
          {template.id ? 'ID: ' + template.id : ''}
        </div>
        {renderHeaderInfo()}
      </div>
      <Checkbox
        key={'visibility-checkbox'}
        switch={setIsVisible}
        isActive={isVisible}
        text={'VISIBILITY'}
        isCircle={true}
      />
    </div>

  };

  const renderAddToCollection = () => {
    return <div style={{...{display: 'flex', marginTop: '20px', flexDirection: 'column', width: '200px'}}}>
      <div className={'reels-second-text'}>
        Collection
      </div>
      <DropdownWrapper style={{right: '31%', paddingTop: '10px'}}>
        <div className={'reels-input-select-container'} style={{marginTop: '7px', resize: 'vertical'}}>
          <div>{joinCollection ? joinCollection.name : ''}</div>
          <ArrowButton/>
        </div>

        <MoveTo
          moveTo={(template: Template, collection: Collection) => {
            setIsUpdateRelations(true);
            setJoinCollection(collection);
          }}
          items={collections.filter((collection) => {
            return !(joinCollection && collection.id === joinCollection.id) || !template.templateToCollections.find((templateToCollection) => {
              return templateToCollection.collectionId === collection.id;
            });
          })}
          propName={'name'}
          sourceItem={template}
          isClose={true}
          containerClassName={'component-settings-container'}
          isActiveNone={true}
          noneText={'None'}
          onNoneAction={() => {
            setJoinCollection(null);
            setIsUpdateRelations(true);
          }}
        />
      </DropdownWrapper>
    </div>
  }

  const renderTags = () => {
    return <div style={{
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <div className={'reels-second-text'}>
        Tags
      </div>
      <TagsInput
          tagIds={tagIds}
          allTags={tags}
          addTag={ (tag: Tag) => {
            setIsUpdateRelations(true);
            const newTagIds = [...tagIds].filter( (tagId) => tag.id !== tagId);
            newTagIds.push(tag.id);
            setTagIds(newTagIds);
          }}
          removeTag={(removeTagId: string) => {
            const newTagIds = [...tagIds].filter( (tagId) => removeTagId !== tagId);
            setIsUpdateRelations(true);
            setTagIds(newTagIds);
          }}
       sourceItem={template}
      />
    </div>
  };

  const renderBody = () => {
    return <div className={'details-body'}>
      <div style={{display: 'flex', marginTop: '13px'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div className={'reels-second-text'}>
            Image Preview
          </div>
          <EditImg
              imgUrl={previewImageUrl}
              style={{marginTop: '3px', height: '224px', width: '126px'}}
              heightImg={'224px'}
              widthImg={'126px'}
              onDrop={(file) => {
                const fileName = `${nextVersion.id}_${momentZ().tz('UTC').format('x')}_preview_image.${getFileNameExt(file.name)}`;
                const callbackOriginalUrl = (originalUrl: string) => {
                  compressImage(originalUrl, new URL(originalUrl).pathname
                    .replace('original_', '')
                    .replace(`_preview_image.${getFileNameExt(file.name)}`, '_compressed.webp')
                  );
                  setPreviewImageUrl(originalUrl
                    .replace('original_', '')
                    .replace(`_preview_image.${getFileNameExt(file.name)}`, '_compressed.webp')
                  );
                }
                handleFileDrop(file, `original_${fileName}`, setIsLoadingImage, callbackOriginalUrl)
              }}
              isLoading={isLoadingImage || isCompressingImage}
              clear={() => {
                setPreviewImageUrl(null);
              }}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '12px'}}>
          <div className={'reels-second-text'}>
            Video Preview
          </div>
          <EditVideo
              videoUrl={previewVideoUrl}
              style={{marginTop: '3px', height: '224px', width: '126px'}}
              heightVideo={'224px'}
              widthVideo={'126px'}
              onDrop={(file) => {handleFileDrop(file, `original_${nextVersion.id}_${momentZ().tz('UTC').format('x')}_preview_video.mp4`, setIsLoadingVideo, (videoUrl) => {
                compressVideo(videoUrl);
                setPreviewVideoUrl(videoUrl.replace('original_', ''));
              })}}
              isLoading={isLoadingVideo || isCompressingVideo}
              clear={() => {
                setPreviewVideoUrl(null);
              }}
          />
        </div>
      </div>

      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{display: 'flex', marginTop: '20px', flexDirection: 'column', width: '100%'}}>
          <div className={'reels-second-text'}>
                        Music
          </div>
          <div style={{marginTop: '3px', width: '100%'}}>
            <AudioFileInput
              value={audioUrl ? decodeURIComponent(audioUrl) : ''}
              handleChange={(file, fileName) => {handleFileDrop(file, decodeURIComponent(`${nextVersion.id}_${momentZ().tz('UTC').format('x')}_${fileName}`), setIsLoadingAudio, setAudioUrl)}}
              isLoading={isLoadingAudio}
              style={{width: '93%'}}
              clear={() => {setAudioUrl('')}}
              placeholder={'Add Music File'}
            />
          </div>
          <div style={{marginTop: '8px', width: '100%'}}>
            <Input style={{width: '200px'}}
                   handleChange={(artistName: string) => {setArtistName(artistName)}}
                   placeholder={'Artist name'}
                   value={artistName}
            />
            <Input style={{width: '200px', marginLeft: '15px'}}
                   handleChange={(trackName: string) => {setTrackName(trackName)}}
                   placeholder={'Track name'}
                   value={trackName}
            />
          </div>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', marginTop: '8px', width: '100%'}}>
            <Input handleChange={(audioUrl: string) => {setAudioUrls({...audioUrls, ...{[PlatformType.INSTAGRAM]: audioUrl}})}}
                   style={{width: '200px'}}
                   placeholder={'URL IG audio'}
                   value={(audioUrls[PlatformType.INSTAGRAM] ? audioUrls[PlatformType.INSTAGRAM] : "")}
            />
            <Input handleChange={(audioUrl: string) => {setAudioUrls({...audioUrls, ...{[PlatformType.TIKTOK]: audioUrl}})}}
                   style={{width: '200px', marginLeft: '15px'}}
                   placeholder={'URL TikTok audio'}
                   value={(audioUrls[PlatformType.TIKTOK] ? audioUrls[PlatformType.TIKTOK] : "")}
            />
      </div>
        <Input handleChange={(audioUrl: string) => {setAudioUrls({...audioUrls, ...{[PlatformType.YOUTUBE]: audioUrl}})}}
               style={{marginTop: '8px', width: '200px'}}
               placeholder={'URL YouTube audio'}
               value={(audioUrls[PlatformType.YOUTUBE] ? audioUrls[PlatformType.YOUTUBE] : "")}
        />
      <CheckboxTemplateDetails
        isFree={isFree}
        setIsFree={(isActive) => {handleClickCategory('free', isActive);}}
        setIsNew={(isActive) => {handleClickCategory('new', isActive);}}
        setIsPopular={(isActive) => {handleClickCategory('most_popular', isActive);}}
        setIsUniversal={(isActive) => {handleClickCategory('universal_templates', isActive);}}
        setIsHotTrends={(isActive) => {handleClickCategory('hot_trends', isActive);}}
        setIsBestForRecaps={(isActive) => {handleClickCategory('best_for_recaps', isActive);}}
        isNew={isNew}
        isPopular={isPopular}
        isUniversal={isUniversal}
        isHotTrends={isHotTrends}
        isBestForRecaps={isBestForRecaps}
      />
      {renderCategories()}
      {renderTags()}
      {renderAddToCollection()}
      <TemplateClipsView
        clips={clips}
        updateClips={(clips) => {
          setClips(clips);
        }}
        isValidClips={isValidClips}
        setIsValidClips={setIsValidClips}
        style={{width: '100%', marginTop: '20px', maxHeight: '330px', backgroundColor: (!isValidClips ? '#ee5656' : 'rgba(0,0,0,0)')}}
      />
    </div>;
  };

  return <div className="details-container">
    {renderHeader()}
    {renderBody()}
    <DetailsFooter
      mode={mode}
      deleteEntity={deleteTemplate}
      saveText={'Save'}
      deleteText={'Delete Template'}
      cancel={cancel}
      entity={template}
      handleSave={handleSaveTemplate}
      isDisabled={!isValidClips || isCompressingVideo || !previewVideoUrl || !previewImageUrl || artistName === '' || trackName === '' || isEmptyAudioUrls() || clips.length === 0 || (templateToCategories.length === 0 && !joinCollection)}
    />
  </div>;
};

const mapStateToProps = (state: any) => ({
  categories: state.categories.categories,
  collections: state.collections.collections,
  templatesByCategoriesIds: state.templates.templatesByCategoriesIds,
  templatesCount: state.templates.templates.length,
  tags: state.tags.tags,
  nextVersion: state.appState.nextVersion,
  currentVersion: state.appState.version,
  templatesViewCount: state.templates.templatesViewCount,
  templatesExportCount: state.templates.templatesExportCount,
  isCompressingVideo: state.appState.isCompressingVideo,
  isCompressingImage: state.templates.isCompressingImage
});

const mapDispatchToProps = (dispatch: any) => ({
  addTemplate: bindActionCreators(addTemplate, dispatch),
  updateTemplate: bindActionCreators(updateTemplate, dispatch),
  compressVideo: bindActionCreators(compressVideo, dispatch),
  compressImage: bindActionCreators(compressImage, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDetails);
