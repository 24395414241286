import React from 'react';
import uuid from 'uuid';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {showModal} from '../../actions/general';
import Modal from './Modal';
import {DeleteAttention} from './DeleteAttention';
import PublishFeedDialog from './PublishFeedDialog';
import {deleteTag, setDeleteTag, setEditTag} from '../../actions/tags';
import {deleteCategory, deleteTrackCategory, setDeleteCategory, setDeleteTrackCategory, setEditCategory, setEditTrackCategory} from '../../actions/categories';
import CategoryDetails from '../categories/CategoryDetails';
import {DetailsModeType, PlatformType} from '../../models/Common';
import {ModalType} from '../../models/Modals';
import {Category} from '../../models/Category';
import {Tag} from '../../models/Tag';
import TemplateDetails from '../templates/TemplateDetails';
import {Template} from '../../models/Template';
import {deleteTemplate, setDeleteTemplate, setEditTemplate} from '../../actions/templates';
import {Version} from '../../models/Version';
import TagDetails from '../tags/TagDetails';
import CollectionDetails from '../collections/CollectionDetails';
import {deleteCollection, setDeleteCollection, setEditCollection} from '../../actions/collections';
import {Collection} from '../../models/Collection';
import {TrackCategory} from '../../models/TrackCategory';
import {ReelsTrackSourceType, Track} from '../../models/Track';
import {deleteTrack, setDeleteTrack, setEditTrack} from '../../actions/tracks';
import TrackCategoryDetails from '../music/trackCategories/TrackCategoryDetails';
import TrackDetails from '../music/tracks/TrackDetails';

interface StateProps {
    categoryForDelete: Category,
    categoryForEdit: Category,
    collectionForDelete: Collection,
    collectionForEdit: Collection,
    templateForDelete: Template,
    templateForEdit: Template,
    trackCategoryForDelete: TrackCategory,
    trackCategoryForEdit: TrackCategory,
    trackForDelete: Track,
    trackForEdit: Track,
    tagForDelete: Tag,
    tagForEdit: Tag,
    selectedPlatformType: PlatformType,
    nextVersion: Version
}

interface DispatchProps {
    setDeleteCategory: (category?: Category | null) => void,
    setEditCategory: (category?: Category | null) => void,
    deleteCategory: (categoryId: string) => void,
    setDeleteTrackCategory: (category?: TrackCategory | null) => void,
    setEditTrackCategory: (category?: TrackCategory | null) => void,
    deleteTrackCategory: (categoryId: string) => void,
    setDeleteCollection: (collection?: Collection | null) => void,
    setEditCollection: (collection?: Collection | null) => void,
    deleteCollection: (collectionId: string) => void,
    setDeleteTemplate: (template?: Template | null) => void,
    setEditTemplate: (template?: Template | null) => void,
    deleteTemplate: (templateId: string) => void,
    setDeleteTrack: (track?: Track | null) => void,
    setEditTrack: (track?: Track | null) => void,
    deleteTrack: (trackId: string) => void,
    showModal: (modalType: ModalType, isShow: boolean, meta?: any) => void,
    setDeleteTag: (tag?: Tag | null) => void,
    setEditTag: (tag?: Tag | null) => void,
    deleteTag: (tagId: string) => void,
}

interface OwnProps {

}

type Props = StateProps & DispatchProps & OwnProps


const Modals = ({deleteCategory, setEditCategory, setDeleteCategory, showModal, templateForEdit, nextVersion,
  categoryForDelete, categoryForEdit, tagForDelete, setDeleteTag, deleteTag, templateForDelete,
  trackCategoryForEdit, trackCategoryForDelete, trackForEdit, trackForDelete,
  setDeleteTrack, setEditTrack, deleteTrack, setDeleteTrackCategory, setEditTrackCategory, deleteTrackCategory,
   setEditTag, tagForEdit, setDeleteTemplate, setEditTemplate, deleteTemplate, selectedPlatformType,
  deleteCollection, setEditCollection, setDeleteCollection, collectionForDelete, collectionForEdit}: Props) => {
  return <div style={{position: 'relative'}}>

    <Modal modalType={ModalType.CATEGORY_EDIT} onClose={() => setEditCategory(null)}>
      <CategoryDetails
          mode={DetailsModeType.EDIT}
          category={categoryForEdit}
          cancel={() => {
            setEditCategory(null);
          }}
          deleteCategory={(category) => {
            setDeleteCategory(category);
            setEditCategory(null);
          }}
      />
    </Modal>
    <Modal modalType={ModalType.CATEGORY_DELETE} onClose={() => setDeleteCategory(null)}>
      <DeleteAttention
        description={'Category ' + (categoryForDelete ? categoryForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteCategory(null);
        }}
        onDelete={() => {
          setDeleteCategory(null);
          deleteCategory(categoryForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.CATEGORY_ADD}>
      <CategoryDetails
        mode={DetailsModeType.ADD}
        category={{
          id: uuid.v4(),
          name: '',
          localization: {},
          isActive: true,
          isFeatured: false,
          order: 4,
          templatesToCategory: [],
          createdAt: new Date(),
          updatedAt: new Date(),
        }}
        cancel={() => {
          showModal(ModalType.CATEGORY_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={ModalType.TRACK_CATEGORY_EDIT} onClose={() => setEditTrackCategory(null)}>
      <TrackCategoryDetails
        mode={DetailsModeType.EDIT}
        category={trackCategoryForEdit}
        cancel={() => {
          setEditTrackCategory(null);
        }}
        deleteCategory={(category) => {
          setDeleteTrackCategory(category);
          setEditTrackCategory(null);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.TRACK_CATEGORY_DELETE} onClose={() => setDeleteTrackCategory(null)}>
      <DeleteAttention
        description={'Category ' + (trackCategoryForDelete ? trackCategoryForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteTrackCategory(null);
        }}
        onDelete={() => {
          setDeleteTrackCategory(null);
          deleteTrackCategory(trackCategoryForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.TRACK_CATEGORY_ADD}>
      <TrackCategoryDetails
        mode={DetailsModeType.ADD}
        category={{
          id: uuid.v4(),
          name: '',
          localization: {},
          isActive: true,
          order: 0,
          tracksToCategory: [],
          createdAt: new Date(),
          updatedAt: new Date(),
        }}
        cancel={() => {
          showModal(ModalType.TRACK_CATEGORY_ADD, false);
        }}
      />
    </Modal>

    <Modal modalType={ModalType.COLLECTION_EDIT} onClose={() => setEditCategory(null)}>
      <CollectionDetails
        mode={DetailsModeType.EDIT}
        collection={collectionForEdit}
        cancel={() => {
          setEditCollection(null);
        }}
        deleteCollection={(collection) => {
          setDeleteCollection(collection);
          setEditCollection(null);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.COLLECTION_DELETE} onClose={() => setDeleteCollection(null)}>
      <DeleteAttention
        description={'Collection ' + (collectionForDelete ? collectionForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteCollection(null);
        }}
        onDelete={() => {
          setDeleteCollection(null);
          deleteCollection(collectionForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.COLLECTION_ADD}>
      <CollectionDetails
        mode={DetailsModeType.ADD}
        collection={{
          id: uuid.v4(),
          name: '',
          isActive: true,
          localization: {},
          previewVideoUrl: '',
          order: 0,
          templatesToCollection: [],
          createdAt: new Date(),
          updatedAt: new Date(),
        }}
        cancel={() => {
          showModal(ModalType.COLLECTION_ADD, false);
        }}
      />
    </Modal>

      <Modal modalType={ModalType.TEMPLATE_EDIT} onClose={() => setEditTemplate(null)}>
          <TemplateDetails
              mode={DetailsModeType.EDIT}
              template={templateForEdit}
              cancel={() => {
                  setEditTemplate(null);
              }}
              deleteTemplate={(template) => {
                  setDeleteTemplate(template);
                  setEditTemplate(null);
              }}
          />
      </Modal>
      <Modal modalType={ModalType.TEMPLATE_DELETE} onClose={() => setDeleteTemplate(null)}>
          <DeleteAttention
              description={'Template ' + (templateForDelete ? templateForDelete.artistName : '') + ' ?'}
              cancel={() => {
                  setDeleteTemplate(null);
              }}
              onDelete={() => {
                  setDeleteTemplate(null);
                  deleteTemplate(templateForDelete.id);
              }}
          />
      </Modal>
      <Modal modalType={ModalType.TEMPLATE_ADD}>
          <TemplateDetails
              mode={DetailsModeType.ADD}
              template={{
                  id: uuid.v4(),
                  artistName: '',
                  trackName: '',
                  previewVideoUrl: '',
                  previewImageUrl: '',
                  audioUrls: {},
                  clips: [{duration: 0}],
                  isDeleted: false,
                  isActive: true,
                  isFree: false,
                  isPopular: false,
                  isUniversal: false,
                  isHotTrends: false,
                  isBestForRecaps: false,
                  isNew: false,
                  templateToCategories: [],
                  templateToCollections: [],
                  tagIds: [],
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  version: nextVersion.id,
              }}
              cancel={() => {
                  showModal(ModalType.TEMPLATE_ADD, false);
              }}
          />
      </Modal>
    <Modal modalType={ModalType.TRACK_EDIT} onClose={() => setEditTrack(null)}>
      <TrackDetails
        mode={DetailsModeType.EDIT}
        track={trackForEdit}
        cancel={() => {
          setEditTrack(null);
        }}
        deleteTrack={(track) => {
          setDeleteTrack(track);
          setEditTrack(null);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.TRACK_DELETE} onClose={() => setDeleteTemplate(null)}>
      <DeleteAttention
        description={'Track ' + (trackForDelete ? trackForDelete.artistName : '') + ' ?'}
        cancel={() => {
          setDeleteTrack(null);
        }}
        onDelete={() => {
          setDeleteTrack(null);
          deleteTrack(trackForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.TRACK_ADD}>
      <TrackDetails
        mode={DetailsModeType.ADD}
        track={{
          id: uuid.v4(),
          artistName: '',
          trackName: '',
          mp3Url: '',
          duration: 0,
          coverImageUrl: '',
          sourceType: ReelsTrackSourceType.ONELIGHT,
          isDeleted: false,
          isActive: true,
          isFree: false,
          isPopular: false,
          isNew: false,
          trackToCategories: [],
          createdAt: new Date(),
          updatedAt: new Date(),
          version: nextVersion.id,
        }}
        cancel={() => {
          showModal(ModalType.TRACK_ADD, false);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.TAG_EDIT} onClose={() => setEditTag(null)}>
        <TagDetails
            mode={DetailsModeType.EDIT}
            tag={tagForEdit}
            cancel={() => setEditTag(null)}
            deleteTag={(tag: Tag) => {
                setDeleteTag(tag);
                setEditTag(null);
            }}
        />
    </Modal>
    <Modal modalType={ModalType.TAG_ADD}>
      <TagDetails
          mode={DetailsModeType.ADD}
          tag={{
              isActive: false,
              id: uuid.v4(),
              templatesIds: [],
              name: 'New tag',
              localization: {},
              order: 0,
              createdAt: new Date(),
              updatedAt: new Date(),
          }}
          cancel={() => {
              showModal(ModalType.TAG_ADD, false);
          }}
          deleteTag={(tag: Tag) => {}}
      />
    </Modal>
    <Modal modalType={ModalType.TAG_DELETE} onClose={() => setDeleteTag(null)}>
      <DeleteAttention
        description={'Tag ' + (tagForDelete ? tagForDelete.name : '') + ' ?'}
        cancel={() => {
          setDeleteTag(null);
        }}
        onDelete={() => {
          setDeleteTag(null);
          deleteTag(tagForDelete.id);
        }}
      />
    </Modal>
    <Modal modalType={ModalType.PUBLISH}>
      <PublishFeedDialog
        cancel={() => {
          showModal(ModalType.PUBLISH, false);
        }}
      />
    </Modal>
  </div>;
};

const mapStateToProps = (state: any) => ({
  categoryForDelete: state.appState.categoryForDelete,
  categoryForEdit: state.appState.categoryForEdit,
  trackCategoryForDelete: state.appState.trackCategoryForDelete,
  trackCategoryForEdit: state.appState.trackCategoryForEdit,
  collectionForDelete: state.appState.collectionForDelete,
  collectionForEdit: state.appState.collectionForEdit,
  templateForDelete: state.appState.templateForDelete,
  templateForEdit: state.appState.templateForEdit,
  trackForDelete: state.appState.trackForDelete,
  trackForEdit: state.appState.trackForEdit,
  tagForDelete: state.appState.tagForDelete,
  tagForEdit: state.appState.tagForEdit,
  selectedPlatformType: state.appState.selectedPlatformType,
  nextVersion: state.appState.nextVersion
});

const mapDispatchToProps = (dispatch: any) => ({
  setDeleteCategory: bindActionCreators(setDeleteCategory, dispatch),
  setEditCategory: bindActionCreators(setEditCategory, dispatch),
  deleteCategory: bindActionCreators(deleteCategory, dispatch),
  setDeleteTrackCategory: bindActionCreators(setDeleteTrackCategory, dispatch),
  setEditTrackCategory: bindActionCreators(setEditTrackCategory, dispatch),
  deleteTrackCategory: bindActionCreators(deleteTrackCategory, dispatch),
  setDeleteCollection: bindActionCreators(setDeleteCollection, dispatch),
  setEditCollection: bindActionCreators(setEditCollection, dispatch),
  deleteCollection: bindActionCreators(deleteCollection, dispatch),
  setDeleteTemplate: bindActionCreators(setDeleteTemplate, dispatch),
  setEditTemplate: bindActionCreators(setEditTemplate, dispatch),
  deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
  setDeleteTrack: bindActionCreators(setDeleteTrack, dispatch),
  setEditTrack: bindActionCreators(setEditTrack, dispatch),
  deleteTrack: bindActionCreators(deleteTrack, dispatch),
  showModal: bindActionCreators(showModal, dispatch),
  setDeleteTag: bindActionCreators(setDeleteTag, dispatch),
  setEditTag: bindActionCreators(setEditTag, dispatch),
  deleteTag: bindActionCreators(deleteTag, dispatch),
});

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Modals);
